<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiSkeletonItemProps {
  color?: string;
  duration?: number;
  highlight?: string;
  timing?: string;
}
const props = withDefaults(defineProps<CiSkeletonItemProps>(), {
  color: '#e1e1e1',
  duration: 1.5,
  highlight: 'hsla(0,0%,100%,.3)',
  timing: 'ease-in-out',
});
</script>

<template>
  <div
    class="skeleton bg-[length:200px_100%] bg-no-repeat"
    style="animation-iteration-count: infinite; animation-name: SkeletonLoading"
    :style="{
      animationDuration: `${props.duration}s`,
      animationTimingFunction: `${props.timing}`,
      backgroundColor: `${props.color}`,
      backgroundImage: `linear-gradient(90deg, ${props.color}, ${props.highlight}, ${props.color})`,
    }"
  >
    <slot />
  </div>
</template>

<style></style>
